import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Apollo } from "apollo-angular";
import { FetchBackend, HttpClient } from "@angular/common/http";
import gql from "graphql-tag";

// export const GET_CHARACTERS = gql`
//   {
//     characters {
//       results {
//         name
//       }
//     }
//   }
// `;


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // apollo = inject(Apollo);
  // http = inject(FetchBackend);
  title = 'ng-dom';

  ngOnInit() {
    // console.log(GET_CHARACTERS);
      // this.apollo
      // .query({
      //   query: GET_CHARACTERS,
      // }).subscribe(console.log)
      // .valueChanges.subscribe(console.log)
  }
}
