import { __assign } from "tslib";
import { invariant, newInvariantError } from "../utilities/globals/index.js";
import { ApolloLink, execute } from "../link/core/index.js";
import { version } from "../version.js";
import { HttpLink } from "../link/http/index.js";
import { QueryManager } from "./QueryManager.js";
import { LocalState } from "./LocalState.js";
var hasSuggestedDevtools = false;
import { mergeOptions } from "../utilities/index.js";
export { mergeOptions };
var ApolloClient = function () {
  function ApolloClient(options) {
    var _this = this;
    this.resetStoreCallbacks = [];
    this.clearStoreCallbacks = [];
    if (!options.cache) {
      throw newInvariantError(15);
    }
    var uri = options.uri,
      credentials = options.credentials,
      headers = options.headers,
      cache = options.cache,
      documentTransform = options.documentTransform,
      _a = options.ssrMode,
      ssrMode = _a === void 0 ? false : _a,
      _b = options.ssrForceFetchDelay,
      ssrForceFetchDelay = _b === void 0 ? 0 : _b,
      _c = options.connectToDevTools,
      connectToDevTools = _c === void 0 ? typeof window === "object" && !window.__APOLLO_CLIENT__ && globalThis.__DEV__ !== false : _c,
      _d = options.queryDeduplication,
      queryDeduplication = _d === void 0 ? true : _d,
      defaultOptions = options.defaultOptions,
      defaultContext = options.defaultContext,
      _e = options.assumeImmutableResults,
      assumeImmutableResults = _e === void 0 ? cache.assumeImmutableResults : _e,
      resolvers = options.resolvers,
      typeDefs = options.typeDefs,
      fragmentMatcher = options.fragmentMatcher,
      clientAwarenessName = options.name,
      clientAwarenessVersion = options.version;
    var link = options.link;
    if (!link) {
      link = uri ? new HttpLink({
        uri: uri,
        credentials: credentials,
        headers: headers
      }) : ApolloLink.empty();
    }
    this.link = link;
    this.cache = cache;
    this.disableNetworkFetches = ssrMode || ssrForceFetchDelay > 0;
    this.queryDeduplication = queryDeduplication;
    this.defaultOptions = defaultOptions || Object.create(null);
    this.typeDefs = typeDefs;
    if (ssrForceFetchDelay) {
      setTimeout(function () {
        return _this.disableNetworkFetches = false;
      }, ssrForceFetchDelay);
    }
    this.watchQuery = this.watchQuery.bind(this);
    this.query = this.query.bind(this);
    this.mutate = this.mutate.bind(this);
    this.resetStore = this.resetStore.bind(this);
    this.reFetchObservableQueries = this.reFetchObservableQueries.bind(this);
    this.version = version;
    this.localState = new LocalState({
      cache: cache,
      client: this,
      resolvers: resolvers,
      fragmentMatcher: fragmentMatcher
    });
    this.queryManager = new QueryManager({
      cache: this.cache,
      link: this.link,
      defaultOptions: this.defaultOptions,
      defaultContext: defaultContext,
      documentTransform: documentTransform,
      queryDeduplication: queryDeduplication,
      ssrMode: ssrMode,
      clientAwareness: {
        name: clientAwarenessName,
        version: clientAwarenessVersion
      },
      localState: this.localState,
      assumeImmutableResults: assumeImmutableResults,
      onBroadcast: connectToDevTools ? function () {
        if (_this.devToolsHookCb) {
          _this.devToolsHookCb({
            action: {},
            state: {
              queries: _this.queryManager.getQueryStore(),
              mutations: _this.queryManager.mutationStore || {}
            },
            dataWithOptimisticResults: _this.cache.extract(true)
          });
        }
      } : void 0
    });
    if (connectToDevTools) this.connectToDevTools();
  }
  ApolloClient.prototype.connectToDevTools = function () {
    if (typeof window === "object") {
      var windowWithDevTools = window;
      var devtoolsSymbol = Symbol.for("apollo.devtools");
      (windowWithDevTools[devtoolsSymbol] = windowWithDevTools[devtoolsSymbol] || []).push(this);
      windowWithDevTools.__APOLLO_CLIENT__ = this;
    }
    if (!hasSuggestedDevtools && globalThis.__DEV__ !== false) {
      hasSuggestedDevtools = true;
      setTimeout(function () {
        if (typeof window !== "undefined" && window.document && window.top === window.self && !window.__APOLLO_DEVTOOLS_GLOBAL_HOOK__) {
          var nav = window.navigator;
          var ua = nav && nav.userAgent;
          var url = void 0;
          if (typeof ua === "string") {
            if (ua.indexOf("Chrome/") > -1) {
              url = "https://chrome.google.com/webstore/detail/" + "apollo-client-developer-t/jdkknkkbebbapilgoeccciglkfbmbnfm";
            } else if (ua.indexOf("Firefox/") > -1) {
              url = "https://addons.mozilla.org/en-US/firefox/addon/apollo-developer-tools/";
            }
          }
          if (url) {
            globalThis.__DEV__ !== false && invariant.log("Download the Apollo DevTools for a better development " + "experience: %s", url);
          }
        }
      }, 10000);
    }
  };
  Object.defineProperty(ApolloClient.prototype, "documentTransform", {
    get: function () {
      return this.queryManager.documentTransform;
    },
    enumerable: false,
    configurable: true
  });
  ApolloClient.prototype.stop = function () {
    this.queryManager.stop();
  };
  ApolloClient.prototype.watchQuery = function (options) {
    if (this.defaultOptions.watchQuery) {
      options = mergeOptions(this.defaultOptions.watchQuery, options);
    }
    if (this.disableNetworkFetches && (options.fetchPolicy === "network-only" || options.fetchPolicy === "cache-and-network")) {
      options = __assign(__assign({}, options), {
        fetchPolicy: "cache-first"
      });
    }
    return this.queryManager.watchQuery(options);
  };
  ApolloClient.prototype.query = function (options) {
    if (this.defaultOptions.query) {
      options = mergeOptions(this.defaultOptions.query, options);
    }
    invariant(options.fetchPolicy !== "cache-and-network", 16);
    if (this.disableNetworkFetches && options.fetchPolicy === "network-only") {
      options = __assign(__assign({}, options), {
        fetchPolicy: "cache-first"
      });
    }
    return this.queryManager.query(options);
  };
  ApolloClient.prototype.mutate = function (options) {
    if (this.defaultOptions.mutate) {
      options = mergeOptions(this.defaultOptions.mutate, options);
    }
    return this.queryManager.mutate(options);
  };
  ApolloClient.prototype.subscribe = function (options) {
    return this.queryManager.startGraphQLSubscription(options);
  };
  ApolloClient.prototype.readQuery = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = false;
    }
    return this.cache.readQuery(options, optimistic);
  };
  ApolloClient.prototype.readFragment = function (options, optimistic) {
    if (optimistic === void 0) {
      optimistic = false;
    }
    return this.cache.readFragment(options, optimistic);
  };
  ApolloClient.prototype.writeQuery = function (options) {
    var ref = this.cache.writeQuery(options);
    if (options.broadcast !== false) {
      this.queryManager.broadcastQueries();
    }
    return ref;
  };
  ApolloClient.prototype.writeFragment = function (options) {
    var ref = this.cache.writeFragment(options);
    if (options.broadcast !== false) {
      this.queryManager.broadcastQueries();
    }
    return ref;
  };
  ApolloClient.prototype.__actionHookForDevTools = function (cb) {
    this.devToolsHookCb = cb;
  };
  ApolloClient.prototype.__requestRaw = function (payload) {
    return execute(this.link, payload);
  };
  ApolloClient.prototype.resetStore = function () {
    var _this = this;
    return Promise.resolve().then(function () {
      return _this.queryManager.clearStore({
        discardWatches: false
      });
    }).then(function () {
      return Promise.all(_this.resetStoreCallbacks.map(function (fn) {
        return fn();
      }));
    }).then(function () {
      return _this.reFetchObservableQueries();
    });
  };
  ApolloClient.prototype.clearStore = function () {
    var _this = this;
    return Promise.resolve().then(function () {
      return _this.queryManager.clearStore({
        discardWatches: true
      });
    }).then(function () {
      return Promise.all(_this.clearStoreCallbacks.map(function (fn) {
        return fn();
      }));
    });
  };
  ApolloClient.prototype.onResetStore = function (cb) {
    var _this = this;
    this.resetStoreCallbacks.push(cb);
    return function () {
      _this.resetStoreCallbacks = _this.resetStoreCallbacks.filter(function (c) {
        return c !== cb;
      });
    };
  };
  ApolloClient.prototype.onClearStore = function (cb) {
    var _this = this;
    this.clearStoreCallbacks.push(cb);
    return function () {
      _this.clearStoreCallbacks = _this.clearStoreCallbacks.filter(function (c) {
        return c !== cb;
      });
    };
  };
  ApolloClient.prototype.reFetchObservableQueries = function (includeStandby) {
    return this.queryManager.reFetchObservableQueries(includeStandby);
  };
  ApolloClient.prototype.refetchQueries = function (options) {
    var map = this.queryManager.refetchQueries(options);
    var queries = [];
    var results = [];
    map.forEach(function (result, obsQuery) {
      queries.push(obsQuery);
      results.push(result);
    });
    var result = Promise.all(results);
    result.queries = queries;
    result.results = results;
    result.catch(function (error) {
      globalThis.__DEV__ !== false && invariant.debug(17, error);
    });
    return result;
  };
  ApolloClient.prototype.getObservableQueries = function (include) {
    if (include === void 0) {
      include = "active";
    }
    return this.queryManager.getObservableQueries(include);
  };
  ApolloClient.prototype.extract = function (optimistic) {
    return this.cache.extract(optimistic);
  };
  ApolloClient.prototype.restore = function (serializedState) {
    return this.cache.restore(serializedState);
  };
  ApolloClient.prototype.addResolvers = function (resolvers) {
    this.localState.addResolvers(resolvers);
  };
  ApolloClient.prototype.setResolvers = function (resolvers) {
    this.localState.setResolvers(resolvers);
  };
  ApolloClient.prototype.getResolvers = function () {
    return this.localState.getResolvers();
  };
  ApolloClient.prototype.setLocalStateFragmentMatcher = function (fragmentMatcher) {
    this.localState.setFragmentMatcher(fragmentMatcher);
  };
  ApolloClient.prototype.setLink = function (newLink) {
    this.link = this.queryManager.link = newLink;
  };
  Object.defineProperty(ApolloClient.prototype, "defaultContext", {
    get: function () {
      return this.queryManager.defaultContext;
    },
    enumerable: false,
    configurable: true
  });
  return ApolloClient;
}();
export { ApolloClient };
