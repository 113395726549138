import { __assign } from "tslib";
import { Trie } from "@wry/trie";
import { canUseWeakMap, canUseWeakSet, isNonNullObject as isObjectOrArray } from "../../utilities/index.js";
import { isArray } from "./helpers.js";
function shallowCopy(value) {
  if (isObjectOrArray(value)) {
    return isArray(value) ? value.slice(0) : __assign({
      __proto__: Object.getPrototypeOf(value)
    }, value);
  }
  return value;
}
var ObjectCanon = function () {
  function ObjectCanon() {
    this.known = new (canUseWeakSet ? WeakSet : Set)();
    this.pool = new Trie(canUseWeakMap);
    this.passes = new WeakMap();
    this.keysByJSON = new Map();
    this.empty = this.admit({});
  }
  ObjectCanon.prototype.isKnown = function (value) {
    return isObjectOrArray(value) && this.known.has(value);
  };
  ObjectCanon.prototype.pass = function (value) {
    if (isObjectOrArray(value)) {
      var copy = shallowCopy(value);
      this.passes.set(copy, value);
      return copy;
    }
    return value;
  };
  ObjectCanon.prototype.admit = function (value) {
    var _this = this;
    if (isObjectOrArray(value)) {
      var original = this.passes.get(value);
      if (original) return original;
      var proto = Object.getPrototypeOf(value);
      switch (proto) {
        case Array.prototype:
          {
            if (this.known.has(value)) return value;
            var array = value.map(this.admit, this);
            var node = this.pool.lookupArray(array);
            if (!node.array) {
              this.known.add(node.array = array);
              if (globalThis.__DEV__ !== false) {
                Object.freeze(array);
              }
            }
            return node.array;
          }
        case null:
        case Object.prototype:
          {
            if (this.known.has(value)) return value;
            var proto_1 = Object.getPrototypeOf(value);
            var array_1 = [proto_1];
            var keys = this.sortedKeys(value);
            array_1.push(keys.json);
            var firstValueIndex_1 = array_1.length;
            keys.sorted.forEach(function (key) {
              array_1.push(_this.admit(value[key]));
            });
            var node = this.pool.lookupArray(array_1);
            if (!node.object) {
              var obj_1 = node.object = Object.create(proto_1);
              this.known.add(obj_1);
              keys.sorted.forEach(function (key, i) {
                obj_1[key] = array_1[firstValueIndex_1 + i];
              });
              if (globalThis.__DEV__ !== false) {
                Object.freeze(obj_1);
              }
            }
            return node.object;
          }
      }
    }
    return value;
  };
  ObjectCanon.prototype.sortedKeys = function (obj) {
    var keys = Object.keys(obj);
    var node = this.pool.lookupArray(keys);
    if (!node.keys) {
      keys.sort();
      var json = JSON.stringify(keys);
      if (!(node.keys = this.keysByJSON.get(json))) {
        this.keysByJSON.set(json, node.keys = {
          sorted: keys,
          json: json
        });
      }
    }
    return node.keys;
  };
  return ObjectCanon;
}();
export { ObjectCanon };
